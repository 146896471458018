import React from 'react';
import '../Styles/Home.css';
import image from '../Images/home-bg.png'; // Görseli doğru dizinden ekleyin.
import Header from './Header';

export default function Home() {
    const handleClick = () => {
        window.location.href = '#form';
    }
    return (
        <>
            <Header />
            <div className="home-wrapper">
                <div className="container">
                    <div className="home-container">
                        <div className="image-container">
                            <div className="text-content">
                                <h1 className="title">Ready to become<br /><span className='mind'>one of the real people?</span></h1>
                            </div>
                            <img src={image} alt="Real People" className="home-image" />
                            <button className="apply-now-btn" onClick={handleClick}>Apply Now</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="empty"></div>
        </>
    );
}

