import React from 'react';
import Card from './Card';
import image1 from '../Images/c1.png';
import image2 from '../Images/c2.png';
import image3 from '../Images/c3.png';

export default function CardsPage() {
    return (
        <div className="cards-wrapper">
            <Card
                id="card1"
                title="Who we are?"
                text="As a subsidiary of the innovative powerhouse RealWorks Global, we are RealPeople, a real partner in people & culture strategy."
                imageSrc={image1}
                isFirstCard
            />
            <Card
                id="card2"
                title="What we do?"
                text="Our work revolves around building strong employer brands that attract and retain top talent. By combining insights with innovative approaches, we help organizations tell their unique story and create workplaces where people thrive."
                imageSrc={image2}
                isMidCard
            />
            <Card
                id="card3"
                title="What makes it special?"
                text="At RealPeople, you'll be an essential part of a transformation journey, where your real insights help drive globally impactful work. Here, we don't just celebrate the work we do, we celebrate who we are."
                imageSrc={image3}
                isLastCard
            />
        </div>
    );
}
