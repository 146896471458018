import React, { useState, useEffect } from 'react';
import closeicon from '../Images/Group 216.svg'
import logo from '../Images/RO LOGO.svg'
import { useNavigate } from 'react-router-dom';
import '../Styles/Header.css'

export default function Header() {
    const [menuActive, setMenuActive] = useState(false);
    const navigate = useNavigate();

    const handleNavigation = (path, targetId) => {
        navigate(path);
        setTimeout(() => {
            document.getElementById(targetId).scrollIntoView({ behavior: 'smooth' });
        }, 500); // Sayfa yüklenme süresine göre bu değeri ayarlayabilirsiniz
    };

    const toggleMenu = () => {
        setMenuActive(!menuActive);
        console.log('Menu Toggled:', !menuActive); // Bu satırı ekleyin
    };


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuActive && !event.target.closest('.sidebar') && !event.target.closest('.menu-toggle')) {
                setMenuActive(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuActive]);

    return (
        <nav className="navbar-custom">
            <div className="container-custom">
                <a className="navbar-brand-custom" href="/">
                    <img className='navbar-logo logo1' src={logo} alt="RealWorks Logo" />

                </a>
                <button className="menu-toggle" onClick={toggleMenu}>
                    {menuActive ? '' : '☰'}
                </button>
                <ul className="navbar-nav-custom">
                    <li className="nav-item-custom" onClick={() => handleNavigation('/', 'who')}>
                        <div className="nav-link-custom">who we are</div>
                    </li>
                    <li className="nav-item-custom" onClick={() => handleNavigation('/', 'value')}>
                        <div className="nav-link-custom">value</div>
                    </li>
                    <li className="nav-item-custom" onClick={() => handleNavigation('/', 'global')}>
                        <div className="nav-link-custom">BENEFITS</div>
                    </li>
                    <li className="nav-item-custom" onClick={() => handleNavigation('/', 'fit')}>
                        <div className="nav-link-custom">teams</div>
                    </li>
                    <li className="nav-item-custom" onClick={() => handleNavigation('/', 'intern')}>
                        <div className="nav-link-custom">REALINTERN</div>
                    </li>
                    <li className="nav-item-custom" onClick={() => handleNavigation('/', 'match-menu')}>
                        <div className="nav-link-custom">HOW WE HIRE?</div>
                    </li>
                    <li className="nav-item-custom" onClick={() => handleNavigation('/', 'form')}>
                        <div className="nav-link-custom">APPLY NOW</div>
                    </li>
                </ul>
                <div className={`sidebar ${menuActive ? 'active' : ''}`}>
                    <button className="close-btn" onClick={toggleMenu}>
                        <img src={closeicon} alt="Close Icon" />
                    </button>
                    <ul className="navbar-nav-custom">
                        <li className="nav-item-custom" onClick={() => handleNavigation('/', 'who')}>
                            <div className="nav-link-custom">who we are</div>
                        </li>
                        <li className="nav-item-custom" onClick={() => handleNavigation('/', 'value')}>
                            <div className="nav-link-custom">value</div>
                        </li>
                        <li className="nav-item-custom" onClick={() => handleNavigation('/', 'global')}>
                            <div className="nav-link-custom">BENEFITS</div>
                        </li>
                        <li className="nav-item-custom" onClick={() => handleNavigation('/', 'fit')}>
                            <div className="nav-link-custom">teams</div>
                        </li>
                        <li className="nav-item-custom" onClick={() => handleNavigation('/', 'intern')}>
                            <div className="nav-link-custom">REALINTERN</div>
                        </li>
                        <li className="nav-item-custom" onClick={() => handleNavigation('/', 'match-menu')}>
                            <div className="nav-link-custom">HOW WE HIRE?</div>
                        </li>
                        <li className="nav-item-custom" onClick={() => handleNavigation('/', 'form')}>
                            <div className="nav-link-custom">APPLY NOW</div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
