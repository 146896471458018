import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../Styles/Match.css'; // CSS dosyanızı içe aktarın
import processImage from '../Images/Layer_1 (1).svg'; // Görsel dosyasını içe aktarın

export default function Match() {
    const navigate = useNavigate(); // useNavigate hook'unu kullanın

    const handleButtonClick = () => {
        navigate('/faq'); // Butona tıklanınca /faq sayfasına yönlendir
    };

    return (
        <div className="match-page" id='match-menu'>
            <h1 className="match-title">
                Are we a <span className="match-highlight">real match?</span> <br />
                <span className="match-subtitle">Let's find out together!</span>
            </h1>
            
            <p className="match-text">
                Our recruitment process is a two-way street, where you get to know us, and we get to know you.
            </p>
            <button className="process-button" onClick={handleButtonClick}>Our Process</button>
            <div className="process-container">
                <img src={processImage} alt="Our Process" className="process-image" />
            </div>
        </div>
    );
}
