import React, { useState } from 'react';
import '../Styles/Global.css'; 
import benefitsImage from '../Images/global-image.png';

export default function Global() {
    const benefitsList = [
        'Worldwide Offices',
        'Global Perspective',
        'Your Day, Your Way',
        'RealHybrid',
        'Agile Mindset',
        'RealGatherings',
    ];

    const benefitsContent = {
        'Worldwide Offices': 'Work from any of our global offices and experience new cultures and perspectives.',
        'Global Perspective': 'Join a diverse team that works with clients worldwide. Collaborating with international colleagues enriches our projects with unique perspectives, broadening your horizons and boosting your professional growth.',
        'Your Day, Your Way': 'We believe in celebrating you. Take a special day off on your birthday to relax, recharge, and do what makes you happy.',
        'RealHybrid': 'You get the best of both worlds: work where you feel most inspired and connect in person when it counts.',
        'Agile Mindset': 'We keep that startup energy alive—always staying curious, adaptable, and ready to take smart risks. Complacency isnt our style; were constantly thinking, building, tweaking, and improving. For you, this means endless opportunities to innovate, grow, and make a real impact.',
        'RealGatherings': 'We love getting together to celebrate the good stuff (New Years, summer kickoffs, or just hanging out after work). Our RealGatherings are all about building a strong community where everyone feels like they rock. These moments of fun and connection keep us close-knit and make our work life thrive.',
    };

    const [selectedBenefit, setSelectedBenefit] = useState('');

    const handleBenefitClick = (benefit) => {
        setSelectedBenefit(benefit);
    };

    const handleContainerClick = (e) => {
        // Eğer tıklanan element "benefit-item" değilse seçim iptal edilir
        if (!e.target.closest('.benefit-item')) {
            setSelectedBenefit('');
        }
    };

    return (
        <div className="global-benefits" onClick={handleContainerClick} id='global'>
            <div className="container">
                <h1 className="global-benefits-title">Global Benefits</h1>
                <div className="global-benefits-content">
                    <div className="benefits-list">
                        {benefitsList.map((benefit, index) => (
                            <div
                                key={index}
                                className={`benefit-item`}
                                onClick={() => handleBenefitClick(benefit)}
                            >
                                <div className="benefits-group">
                                    <div className={`benefit-number ${selectedBenefit === benefit ? 'active' : ''}`}>{index + 1}</div>
                                    <div className={`benefit-text ${selectedBenefit === benefit ? 'active' : ''}`}>{benefit}</div>
                                </div>
                                <div className='benefit-gap'></div>
                            </div>
                        ))}
                    </div>
                    <div className="benefits-image-container">
                        {selectedBenefit ? (
                            <div className="benefits-content">
                                <p>{benefitsContent[selectedBenefit]}</p>
                            </div>
                        ) : (
                            <img src={benefitsImage} alt="Benefits" className="benefits-image" />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
