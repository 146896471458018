import React from 'react';
import '../Styles/Card.css';
import arrowIcon from '../Images/ArrowIcon.svg';

export default function Card({ title, text, imageSrc, isFirstCard, isMidCard, isLastCard }) {
    const cardClasses = `card-custom ${isFirstCard ? 'card-custom-first' : ''} ${isMidCard ? 'card-custom-mid' : ''} ${isLastCard ? 'card-custom-last' : ''}`;
    
    return (
        <div className="card-section" id='who'>
            <div className={cardClasses}>
                <div className='card2'>
                    <div className='card3'>
                        <div className='card-left'>
                            <img className='card-left-image' src={imageSrc} alt={title} />
                        </div>
                        <div className='card-right'>
                            <h2 className='card-right-title'>{title}</h2>
                            <p className='card-right-text'>{text}</p>
                        </div>
                        <div className='card-right-icon'>
                            <img src={arrowIcon} alt="Arrow icon" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
